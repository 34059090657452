<script setup>
import Icon from '@/Components/Icon.vue';
import { router } from '@inertiajs/vue3';

const props = defineProps({ 
    icon: String, 
    tooltip: String,
    route_def: {
        route_def: String,
        default: "",
    } 
});

const emit = defineEmits(['onClick']);

const onClick = () => {

    //console.log(route().current(props.route_def));
    //console.log(props.route_def);

    if(props.route_def == '') {
        emit('onClick');
    } else {
        router.get(route(props.route_def));
    }
}

</script>

<template>
    <button :title="$t(props.icon)" class="relative px-5 hover:bg-gray-50 dark:hover:bg-gray-800 grid items-center justify-center group" @click="onClick()"
            :class="route().current(props.route_def) && props.route_def != '' ? 'border-blue-600 dark:border-blue-500 border-b-4':''">
        
        <div class="w-10 h-10  flex items-center justify-center peer" 
            :class="{ 'bg-blue-500 dark:bg-blue-600  group-hover:bg-blue-600 dark:group-hover:text-blue-500 rounded-full' : (props.icon === 'global.create.new'), }">
            <Icon :icon="props.icon"/>
        </div>
    </button>

</template>
