<script setup>
import Icon from '@/Components/Icon.vue';
import { onMounted, onBeforeUnmount, ref, reactive } from 'vue';
import xhr from '@/Lib/xhr.js';

const props = defineProps({ 
    modelValue: String,
    placeholder: {
        placeholder: String, 
        default: '',        
    },
    prefix: {
        prefix: String, 
        default: 'main',        
    },
    liveSearch: {
        liveSearch: Boolean, 
        default: true,        
    },
});

const emit = defineEmits(['update:modelValue', 'search']);

const search = reactive({ 
    progress: false,    // Progress icon while life search
    sets: [],        // Search result
}); 

onMounted(() => {  

    // Used to show and hide context menu
    let searchBar = document.getElementById(props.prefix+'SearchBar');
    let contextMenu = document.getElementById(props.prefix+'ContextMenu');

    if(props.liveSearch) {
        searchBar.addEventListener('focus', () => {
            contextMenu.classList.remove('hidden');
        });
        searchBar.addEventListener('focusout', () => {
            setTimeout(() => {
            if (!document.activeElement.closest('.context-menu') && !document.activeElement.isEqualNode(searchBar)) {
                contextMenu.classList.add('hidden');
            }
            }, 150);
        });
    }
    
    searchBar.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
            // Execute a search like "View All"
            emit('search');
            contextMenu.classList.add('hidden');
            //document.querySelector('.context-menu').classList.add('hidden');
        } else {
            // Live search for other keys
            liveSearch();
        }
    });

});

onBeforeUnmount(() => { });

/**
 * Live search (differs from search in parent view)
 */
const liveSearch = () => {

    if (props.liveSearch) {
        search.progress = true;

        xhr.get("/set/liveSearch", "POST", null);
        xhr.request.onload = function() { 
            if (xhr.request.responseText) {
                search.sets = JSON.parse(xhr.request.responseText).sets; 
            }  
            search.progress = false;
        };
        xhr.send( { text: props.modelValue } );
    }    
}

const play = (set) => {
   window.location.href = "/play/set/" + set.id;
}

</script>

<template>
    <div class="relative flex flex-col gap-2">
        <div class="w-full flex">
            <input type="text" 
                :id="props.prefix+'SearchBar'"
                class="search-bar h-10 w-full color-bg color-font border color-border rounded-md !rounded-r-none shadow-md" 
                autocomplete="off"
                :placeholder="$t(props.placeholder)"
                :value="props.modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
            >
            <div @click="$emit('search')" class="w-10 h-10 rounded-r-md shadow-md flex items-center justify-center color-bg color-font border color-border group">
                <Icon :icon="'search2'"/>
            </div>            
        </div>

        <div :id="props.prefix+'ContextMenu'" class="hidden context-menu absolute left-0 top-12 z-10 overflow-x-auto w-full h-fit border rounded-lg border-gray-200 dark:border-gray-600">
            <table class="table-style">
                <tbody>
                    <tr v-for="set in search.sets" @click="play(set)" class="table-tr-style cursor-pointer">
                        <th scope="row" class="p-2 w-10">
                            <Icon :icon="'play'"/>
                        </th>
                        <th scope="row" class="p-2"> {{ set.name }} </th>
                    </tr>
                    <tr v-if="search.progress" class="table-tr-style">
                        <th scope="row" class="p-2 w-10">
                            <Icon :icon="'progress'"/>
                        </th>
                        <th scope="row" class="p-2"> {{ $t("set.explore.progress") }} </th>
                    </tr>
                    <tr v-else @click="$emit('search')" class="table-tr-style cursor-pointer">
                        <th scope="row" class="p-2 w-10">
                            <Icon :icon="'search2'"/>
                        </th>
                        <th scope="row" class="p-2"> {{ $t("set.explore.view_all") }} </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
     
</template>
