<script setup>
import { reactive, ref, onMounted, onUnmounted } from 'vue';
import { Head, router, usePage, useForm } from '@inertiajs/vue3';
import ButtonNav from '@/Components/ButtonNav.vue';
import ButtonStd from '@/Components/ButtonStd.vue';
import ButtonIcon from '@/Components/ButtonIcon.vue';
import RadioItem from '@/Components/RadioItem.vue';
import Modal from '@/Layouts/Modal.vue';
import xhr from '@/Lib/xhr.js';
import Search from '@/Components/Search.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';
import Note from '@/Components/Note.vue';
import ProgressBar from '@/Components/ProgressBar.vue';

const props = defineProps({
    title: String,
    config: Array,
    notifications: {
        notifications: Array,
        default: [],
    },
    search: {
        search: Boolean,
        default: true,         // indicate if search shall be available
    },
    navBar: {
        navBar: Boolean,
        default: true,        // indicate if navigation bar shall be visivle
    },
});

const emit = defineEmits(['editSet']);

onMounted(() => { 
    hideProgress();
    setMode();
    document.addEventListener('keydown', keydown)
});

onUnmounted(() => {
    document.removeEventListener('keydown', keydown);
});

/**
 * Toggle between light and dark mode
 */
const toggleMode = () => {
    if (state.mode == "light") {
        setMode("dark");
    } else {
        setMode("light");
    }
}

/**
 * Set light or dark mode
 * @param {string} mode undefined = use definition in local storage, light or dark
 */
const setMode = (mode) => {
    if (mode == undefined) {
        state.mode = localStorage.getItem('mode');
    } else {
        state.mode = mode;
    }

    if (state.mode == "light") {
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
    } else {
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
    }

    localStorage.setItem("mode", state.mode);
}

/**
 * Redirect to explore view
 */
const search = () => {
    window.location.href = "/set/explore/" + encodeURIComponent(state.searchText);
}

/**
 * Reactive parameters
 */
const state = reactive({
  menu: false,             // state of navigation menu
  mode: "light",           // mode: light or dark
  searchText: "",          // search text
  notificationView: false, // true = show view with notifications
});

/**
 * Set to be created (default value)
 */
const set = useForm({ 
    id: "",             // used for edit
    name: "", 
    description: "",
    topic:   1,
    level:   1,
    access:  1,
    language_1: 'en',
    language_2: 'en',
    type: 1,
    error: {            // Validation of field values
        name: false,
    },
    edit: false,        // Indicate if in edit mode
}); 

const dialog = ref();

/**
 * Open dialog to create/edit set
 * @param {object} item if undefined 'create' else 'edit'
 */
const openDialog = (item) => { 
    if(item) {
        set.edit   = true;
        
        set.id     = item.id;
        set.name   = item.name;
        set.description = item.description;
        set.type   = item.type;
        set.topic  = item.topic;
        set.level  = item.level;
        set.access = item.access;
        set.language_1 = item.language_1;
        set.language_2 = item.language_2;
        set.text_img_url = item.text_img_url;

    } else {
        set.reset();
        set.edit = false;
    }
    dialog.value.show();
}

/**
 * Hide creation dialog
 */
const hideDialog = () => { 
    dialog.value.hide();
}

/**
 * Handle keydown event when modal is visible
 * @param {*} e 
 */
 const keydown = (e) => {
    if ( dialog.value.isVisible()) {
        if (e.key === 'Escape') { hideDialog(); }
        if (e.key === 'Enter') { createOrEdit(); }
    }
};

const progress = ref();

/**
 * Hide progress bar
 */
const hideProgress = () => { 
    progress.value.hide();
}
/**
 * Show progress bar
 */
const showProgress = () => { 
    progress.value.show();
}

/**
 * Accessable from parent view
 */
defineExpose({
    openDialog,
    hideProgress,
    showProgress,
})

/**
 * Create set and redirect to edit page
 */
const createOrEdit = () => { 

    set.error.name = set.name == '' ? true:false;

    if (!set.error.name) {
        showProgress();

        // Edit ...
        if(set.edit) {
            xhr.get("/set/updateOrCreate", 'POST');
            xhr.request.onload = function() {

                emit('editSet', JSON.parse(xhr.request.responseText).set);

                hideDialog();
                hideProgress();
            };
            xhr.send( { args: set } );
        }
        // Create ...
        else {
            set.type = parseInt(document.querySelector('input[name="create"]:checked').value);
        
            xhr.get("/set/store", 'POST');
            xhr.request.onload = function() {

                window.location.href = route('item.show', JSON.parse(xhr.request.responseText).set_id);

                hideProgress();
            };
            xhr.send( { args: set } );
        }
    }
}

/**
 * Edit set without items etc
 */
 /*const edit = () => { 

    set.error.name = set.name == '' ? true:false;

    if (!set.error.name) {
        showProgress();
        
        xhr.get("/set/updateOrCreate", 'POST');
        xhr.request.onload = function() {

            emit('editSet', JSON.parse(xhr.request.responseText).set);

            hideDialog();
            hideProgress();
        };
        xhr.send( { args: set } );
    }
}*/

/**
 * Action of notifications
 * @param {String} action = confirm or reject        
 * @param {Object} notification Notification object
 */
const notifyAction = (action, notification) => {
    //console.log(action, notification);

    xhr.get("/notification/answer", "POST", null);
    xhr.send( { id: notification.id, answer: action } );

    // Delete in frontend...
    props.notifications.splice(props.notifications.findIndex(n => n.id == notification.id), 1); 
}

</script>

<template>
    <div>
        <Head :title="$t(props.title)"/>
        <ProgressBar ref="progress"/>

        <div class="min-h-screen bg-gradient-to-b 
            from-zinc-50 via-stone-50 to-orange-50 
            dark:from-slate-950 dark:via-zinc-950 dark:to-blue-950">

            <!-- Live search -->
            <Search v-if="props.search" v-model="state.searchText" :placeholder="'set.explore.search'" :class="'!fixed top-2 left-4 w-[calc(100vw-128px)] sm:w-96 z-40'" @search="search"/>

            <!-- Dark / light mode icon -->
            <div @click="toggleMode()" class="fixed top-2 right-4 flex items-center justify-center z-30 w-10 h-10 bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-600 rounded-full hover:bg-gray-50 dark:hover:bg-gray-700 group">
                <svg v-if="state.mode = 'dark'" class="w-5 h-5 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 fill-current" viewBox="0 0 24 24" >
                    <path d="M12 6a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1Zm9 5h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2ZM6 12a1 1 0 0 0-1-1H3a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1Zm.22-7a1 1 0 0 0-1.39 1.47l1.44 1.39a1 1 0 0 0 .73.28 1 1 0 0 0 .72-.31 1 1 0 0 0 0-1.41ZM17 8.14a1 1 0 0 0 .69-.28l1.44-1.39A1 1 0 0 0 17.78 5l-1.44 1.42a1 1 0 0 0 0 1.41 1 1 0 0 0 .66.31ZM12 18a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1Zm5.73-1.86a1 1 0 0 0-1.39 1.44L17.78 19a1 1 0 0 0 .69.28 1 1 0 0 0 .72-.3 1 1 0 0 0 0-1.42Zm-11.46 0-1.44 1.39a1 1 0 0 0 0 1.42 1 1 0 0 0 .72.3 1 1 0 0 0 .67-.25l1.44-1.39a1 1 0 0 0-1.39-1.44ZM12 8a4 4 0 1 0 4 4 4 4 0 0 0-4-4Zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z"/>
                </svg>
                <svg v-else class="w-4 h-4 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 fill-current" viewBox="0 0 16 16">
                    <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z"/>
                </svg>
            </div>

            <!-- Notifications (show only if notifications are present)  -->
            <div v-if="props.notifications.length > 0" id="notification" @click="state.notificationView = !state.notificationView" class="fixed top-2 right-16 flex items-center justify-center z-30 w-10 h-10 bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-600 rounded-full hover:bg-gray-50 dark:hover:bg-gray-700 group">
                <svg id="notification-icon" class="w-4 h-4 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 fill-current" viewBox="0 0 36 36">
                    <path d="M32.51 27.83A14.4 14.4 0 0 1 30 24.9a12.63 12.63 0 0 1-1.35-4.81v-4.94A10.81 10.81 0 0 0 19.21 4.4V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.33 10.73v4.94a12.63 12.63 0 0 1-1.35 4.81 14.4 14.4 0 0 1-2.47 2.93 1 1 0 0 0-.34.75v1.36a1 1 0 0 0 1 1h27.8a1 1 0 0 0 1-1v-1.36a1 1 0 0 0-.34-.75ZM5.13 28.94a16.17 16.17 0 0 0 2.44-3 14.24 14.24 0 0 0 1.65-5.85v-4.94a8.74 8.74 0 1 1 17.47 0v4.94a14.24 14.24 0 0 0 1.65 5.85 16.17 16.17 0 0 0 2.44 3Z"/>
                    <path d="M18 34.28A2.67 2.67 0 0 0 20.58 32h-5.26A2.67 2.67 0 0 0 18 34.28Z"/>
                </svg>
                <div v-if="props.notifications.length > 0" class="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-0 -end-0 dark:border-gray-900 z-[31]">
                    {{ props.notifications.length > 9 ? "9": props.notifications.length }}
                </div>
            </div>

            <div id="notification-result" class="fixed top-14 right-4 z-30"  v-show="state.notificationView" >
                <div class="relative overflow-x-auto bg-white dark:bg-gray-900 border rounded-lg border-gray-200 dark:border-gray-600 w-[calc(100vw-32px)] sm:w-96 h-fit">
                    <table class="w-full table-auto text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-lg">
                        <thead class="">
                            <tr>
                                <th scope="col" class="p-2"> {{ $t('notification.message') }} </th>
                                <th scope="col" class="w-24 p-2"> {{ $t('notification.action') }} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-show="props.notifications.length == 0" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <th scope="row" class="p-2"> {{ $t("notification.empty") }} </th>
                            </tr>
                            <tr v-for="notification in props.notifications" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <th scope="row" class="p-2"> {{ notification.action }} </th>
                                <th v-if="notification.event_type == 1" scope="row" class="p-2 flex justify-end gap-1">
                                    <ButtonIcon :icon="'confirm2'" @onClick="notifyAction('confirm', notification)"/> 
                                    <ButtonIcon :icon="'close'"    @onClick="notifyAction('reject', notification)"/>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Page heading -->
            <header v-if="$slots.header">
                <div class="max-w-7xl mx-auto pt-14 px-4 sm:px-6 lg:px-8 flex gap-2 items-center justify-start">
                    <slot name="header" />
                </div>
            </header>

            <!-- Page content -->
            <main v-if="$slots.header">
                <div class="max-w-7xl mx-auto pt-4 pb-[90px] px-4 sm:px-6 lg:px-8 flex flex-wrap gap-2">
                    <slot />
                </div>
            </main>

            <!-- Nav bar -->
            <div v-if="props.navBar == true && !state.menu" @click="state.menu = !state.menu" class="fixed flex items-center justify-center z-30 w-16 h-16 bg-white dark:bg-gray-300 border border-gray-200 dark:border-gray-600 rounded-full bottom-4 left-1/2 -translate-x-1/2 hover:bg-gray-50 dark:hover:bg-gray-100"  >
                <img class="w-8 h-8" src="/asset/media/logo/logo-s.svg" alt="Logo icon">
            </div>
            <div v-if="props.navBar == true && state.menu" class="fixed flex z-30 w-fit h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-4 left-1/2 dark:bg-gray-700 dark:border-gray-600">
                <ButtonNav :icon="'navbar.hide'" @onClick="state.menu = !state.menu" class="rounded-s-full" />
                <ButtonNav :icon="'activity'" :route_def="'report.show'"/>
                <ButtonNav :icon="'set.library'" :route_def="'set.library'"/>
                <ButtonNav v-if="usePage().props.auth.user.role == 1" :route_def="'school.show'" :icon="'school'"/>
                <ButtonNav :icon="'auth.profile'" :route_def="'user.profile'"/>
                <ButtonNav :icon="'global.create.new'" @onClick="openDialog()" class="rounded-e-full" />
            </div>

        </div>

        <!-- Select type of creation -->
        <Modal :idC="'dialog'" ref="dialog" :title="set.edit ? 'global.edit.set':'global.create.set'" :fullSize="false">
            
            <template #content>
                <form id="myForm" v-show="!state.collapsed" class="p-3">
                    <div class="grid grid-cols-12 gap-3 flex-grow">
                        <!-- Name -->
                        <div class="col-span-12">
                            <InputLabel for="layout.name" :value="$t('global.name')" />
                            <TextInput id="layout.name" v-model="set.name" :maxlength="30" type="text" class="mt-1 block w-full" autocomplete="name" :placeholder="$t('global.name.placeholder')" />
                            <Note v-if="set.error.name" :text="'error.item_name.required'" class="!text-red-600 dark:!text-red-400"/>
                        </div>

                        <!-- Description -->
                        <div class="col-span-12">
                             <div class="flex gap-1">
                                <InputLabel for="layout.description" :value="$t('global.description')"/>
                                <Note :text="'(' + $t('global.optional') + ')'"/>
                            </div>
                            <TextInput id="layout.description" v-model="set.description" :maxlength="60" type="text" class="mt-1 block w-full" autocomplete="description" :placeholder="$t('global.description.placeholder')" />
                        </div>

                        <!-- Topic -->
                        <div class="col-span-12 md:col-span-4">
                            <InputLabel for="layout.topic" :value="$t('topic')" />
                            <select v-model="set.topic" id="layout.topic" class="color-border color-font color-bg mt-1 block w-full rounded-md" label="topic">
                                <option v-for="(value, key) in props.config.topics" :value="key">{{ $t(value) }}</option>
                            </select>
                        </div>

                        <!-- Level -->
                        <div class="col-span-12 sm:col-span-6 md:col-span-4">
                            <InputLabel for="layout.level" :value="$t('school.level')" />
                            <select v-model="set.level" id="layout.level" class="color-border color-font color-bg mt-1 block w-full rounded-md" label="level">
                                <option v-for="(value, key) in props.config.school_levels" :value="key">{{ $t(value) }}</option>
                            </select>
                        </div>

                        <!-- Access -->
                        <div class="col-span-12 sm:col-span-6 md:col-span-4">
                            <InputLabel for="layout.access" :value="$t('access')" />
                            <select v-model="set.access" id="layout.access" class="color-border color-font color-bg mt-1 block w-full rounded-md" label="access">
                                <option v-for="(value, key) in props.config.access_levels" :value="key">{{ $t(value) }}</option>
                            </select>
                        </div>

                        <!-- Language of Terms -->
                        <div class="col-span-12 sm:col-span-6 md:col-span-6">
                            <InputLabel for="layout.language_1" :value="$t('global.language.main')" />
                            <select v-model="set.language_1" id="layout.language_1" class="color-border color-font color-bg mt-1 block w-full rounded-md" label="language_1">
                                <option v-for="(value, key) in props.config.languages" :value="key">{{ $t(value) }}</option>
                            </select>
                        </div>

                        <!-- Language of Definitions -->
                        <div class="col-span-12 sm:col-span-6 md:col-span-6">
                            <InputLabel for="layout.language_2" :value="$t('global.language.sub')" />
                            <select v-model="set.language_2" id="layout.language_2" class="color-border color-font color-bg mt-1 block w-full rounded-md" label="language_2">
                                <option v-for="(value, key) in props.config.languages" :value="key">{{ $t(value) }}</option>
                            </select>
                        </div>

                        <!-- Set Type -->
                        <ul v-if="set.edit == false" class="col-span-12 grid w-full gap-6 md:grid-cols-2">
                            <RadioItem :name="'create'" :id="'type.flashcard'" :value="1" :checked="set.type == 1 ? true:false"/>
                            <RadioItem :name="'create'" :id="'type.testset'"   :value="2" :checked="set.type == 2 ? true:false"/>
                        </ul>

                    </div >
                </form>                
            </template>

            <template #control>
                <ButtonStd :active="false" @click="hideDialog()" :label="'global.cancel'"/> 
                <ButtonStd :active="true" @click="createOrEdit()" :label="set.edit ? 'global.edit':'global.create'"/>
            </template>

        </Modal>
    </div>
</template>